<template>
  <div class="changePasswordCard">
    <div class="position-relative mb-30">
      <base-input
        class="passwordResetPopup__input"
        v-model="oldPassword"
        :error="v$.oldPassword.$error"
        :error-message="v$.oldPassword.$errors"
        :maxlength="32"
        :type="passwordInputType"
      >
        {{ $t("CurrentPassword") }}
      </base-input>
      <div
        class="registrationPage__passwordIcon"
        @click="showPassword = !showPassword"
      >
        <show-password-icon />
      </div>
    </div>
    <div class="position-relative mb-30">
      <base-input
        class="passwordResetPopup__input"
        v-model="password"
        :error="v$.password.$error"
        :error-message="v$.password.$errors"
        :maxlength="32"
        :type="passwordInputType"
      >
        {{ $t("NewPassword") }}
      </base-input>
      <base-button
        v-if="password.length === 0"
        class="buttons__passwordGenerate"
        @click="passwordGenerate()"
      >
        {{ $t("generate") }}
      </base-button>
      <div
        v-else
        class="registrationPage__passwordIcon"
        @click="showPassword = !showPassword"
      >
        <show-password-icon />
      </div>
    </div>
    <div class="position-relative mb-30">
      <base-input
        class="passwordResetPopup__input"
        v-model="repeatPassword"
        :error="v$.repeatPassword.$error"
        :error-message="v$.repeatPassword.$errors"
        :maxlength="32"
        :type="passwordInputType"
      >
        {{ $t("RepeatNewPassword") }}
      </base-input>
      <div
        v-if="password.length !== 0"
        class="registrationPage__passwordIcon"
        @click="showPassword = !showPassword"
      >
        <show-password-icon />
      </div>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <router-link to="/" class="accountPage__cancel">
        {{ $t("CancelPasswordChange") }}
      </router-link>
      <base-button
        class="passwordResetPopup-submit"
        @click="setNewPassword()"
        :book="true"
      >
        {{ $t("SetNewPassword") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/UI/inputs/BaseInput";
import BaseButton from "@/components/UI/buttons/BaseButton";
import ShowPasswordIcon from "@/components/UI/icons/ShowPasswordIcon";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification";
import { required, sameAs } from "@vuelidate/validators";
import { mapGetters } from "vuex";

export default {
  name: "ChangePasswordCard",
  components: { ShowPasswordIcon, BaseButton, BaseInput },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  validations() {
    return {
      oldPassword: { required },
      password: { required, sameAs: sameAs(this.repeatPassword) },
      repeatPassword: { required, sameAs: sameAs(this.password) },
    };
  },
  data() {
    return {
      showPassword: false,
      oldPassword: "",
      password: "",
      repeatPassword: "",
    };
  },
  methods: {
    setNewPassword() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      this.$http
        .patch("/users/password", {
          password: this.password,
          oldPassword: this.oldPassword,
          token: this.$store.getters.CHANGE_PASSWORD_TOKEN,
        })
        .then(() => {
          this.toast.success(this.$t("PasswordSuccessfullyChange"));
          this.$router.push("/");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    logout() {
      this.$http.post("/auth/logout").then(() => {
        localStorage.removeItem("cart");
        this.sockets.unsubscribe("receive-message");
        this.sockets.unsubscribe("receive-chats");
        this.$router.push("/login");
      });
    },
    passwordGenerate() {
      this.password = Math.floor(Math.random() * 1000000000000000000).toString(
        36
      );
      this.repeatPassword = this.password;
    },
  },

  computed: {
    ...mapGetters({
      popupsInformation: "CHANGE_EMPLOYEE_PASSWORD_LEAVE_POPUP",
    }),
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },
  },
  watch: {
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.setNewPassword();
        }
      },
    },
  },
};
</script>

<style></style>
