<template>
  <main-layout>
    <h1>{{ $t("ChangeYourPassword") }}</h1>
    <change-password-card v-if="step.successesToken" />
    <div v-if="step.wrongToken">
      <div>{{ $t("SomethingWentWrong") }}</div>
      <div>{{ $t("AnnErrorOccuredDuringAccountActivation") }}</div>
      <div>{{ $t("PleaseFollowTheLinkFromEmail") }}</div>
      <base-button
        link="mailto:support@aura-impact.com"
        class="forgotPassword__contact-btn"
      >
        {{ $t("sidebarContactSupportBtn") }}
      </base-button>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import ChangePasswordCard from "@/components/elements/card/ChangePasswordCard.vue";
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import { mapGetters } from "vuex";

export default {
  name: "AccountChangePasswordPage",
  components: { BaseButton, ChangePasswordCard, MainLayout },
  data() {
    return {
      step: {
        wrongToken: false,
        successesToken: true,
      },
      popupState: true,
    };
  },
  mounted() {
    if (this.$route.query.token) {
      this.checkToken(this.$route.query.token);
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    checkToken(token) {
      this.$http
        .get(`/users/verify-password-token/${token}`)
        .then(({}) => {
          this.setStep("successesToken");
          this.$store.commit("SET_CHANGE_PASSWORD_TOKEN", token);
        })
        .catch(() => {
          this.setStep("wrongToken");
        });
    },
    setStep(key) {
      for (let i in this.step) {
        this.step[i] = key === i;
      }
    },
  },
  computed: {
    ...mapGetters({
      popupsInformation: "CHANGE_EMPLOYEE_PASSWORD_LEAVE_POPUP",
    }),
  },
  watch: {
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          setTimeout(() => {
            this.popupState = false;
            this.$router.push(newCount.to);
          }, 250);
        }
        if (newCount.leave) {
          this.popupState = false;
          this.$router.push(newCount.to);
        }
      },
    },
  },
};
</script>

<style scoped></style>
